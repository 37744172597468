@import "../../styles/vars";
@import "../../styles/mixins/type";
@import '../../styles/mixins/breakpoints';

.hero-stat {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;

  %__pic {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 60%;
    background: url('./team-work.png') no-repeat center center;
    background-size: contain;
  }

  &__pic-en {
    @extend %__pic;
    right: 0;
  }

  &__pic-ar {
    @extend %__pic;
    left: 0;
  }


  &__content {
    position: relative;
    padding-bottom: 1rem;
  }
  &__title {
    display: flex;
    font-size: 18px;
    margin-bottom: 0.5rem;
  }
  &__data {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  &__description {
    display: flex;
    font-size: 16px;
    color: $color-black40;
    margin-bottom: 4rem;
  }

  &__sub-content {
    position: relative;
    padding-bottom: 0.5rem;
  }
  &__sub-title {
    display: flex;
    font-size: 14px;
    margin-bottom: 0.4rem;
  }
  &__sub-data {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  &__sub-description {
    display: flex;
    font-size: 12px;
    color: $color-black40;
  }

  @include media-breakpoint-down(lg) {
    &__pic {
      opacity: 0.5;
    }
  }
  @include media-breakpoint-down(md) {
    &__data {
      font-size: 22px;
    }
  }
}
